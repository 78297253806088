import React from "react";
import { Link } from "gatsby";
import IconLogo from "../assets/icons/experro-logo.svg";


const partnerLoginPage = () => {
    return (
        <div className="template-partnerlogin">
          <div className="container d-flex align-v-center justify-center">
            <div className="login-form">
                <div className="logo-block">
                        <Link to="/">
                            <IconLogo />
                        </Link>
                    </div>
                <h4>Experro Partner Portal</h4>
                <p className="mb-32 small-text">Sign in to your experro partner account.</p>
                <form className="form">
                  <div className="form-field">
                    <label class="form-label small-text">Email</label>
                    <input class="form-input" required type="email"></input>
                  </div>
                  <div className="form-field">
                    <label class="form-label small-text">Password</label>
                    <input class="form-input"  type="password"></input>
                  </div>
                  <div className="bottom-links d-flex align-v-center justify-space">
                  <div className="checkmark d-flex align-v-center">
                    <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"></input>
                    <label className="form-label small-text mb-0" for="styled-checkbox-1">Remember me</label>
                  </div>
                  <div className="forgotpassword-block small-text"><Link to="#">Forgot Password?</Link></div>
                  </div>
                  <div className="form-actions">
                  <Link className="button button-small" to="#">Sign in</Link>
                  </div>
                </form>
            </div>
          </div>
        </div>
    )
  }
  
  export default partnerLoginPage